<template>
    <v-card class="pa-5" id="filter">
        <h5 class="body-1 orange--text font-weight-bold">Filter Stays ({{hotelsCount}} Stays)</h5>
        <v-divider class="my-3"></v-divider>
        <div @click="showPriceFilter = !showPriceFilter" class="d-flex justify-space-between">
          <h4 class="primary--text">Prices</h4>
           <v-icon right v-text="showPriceFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>
         <v-expand-transition>
          <div v-show="showPriceFilter">
            <v-row justify="space-between" class="mt-3">
              <v-subheader color="paragraph--text">{{hotels[0].pricingInfo.currency}} {{min}}</v-subheader>
              <v-subheader color="paragraph--text">{{hotels[0].pricingInfo.currency}} {{max}}</v-subheader>
            </v-row>
            <v-range-slider
            v-model="prices"
            :min="min"
            :max="max"
            color="secondary"
            hide-details
            class="align-center"
            thumb-label
            @change="filterate()"
            >
            </v-range-slider>
          </div>
         </v-expand-transition>
        <v-divider class="my-5"></v-divider>
        <div class="d-flex justify-space-between" @click="showStarsFilter = !showStarsFilter">
          <h4 class="primary--text mt-1">Stars</h4>
          <v-icon right v-text="showStarsFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>
        <v-expand-transition>
          <div v-show="showStarsFilter">
            <v-checkbox v-for="n in 5" :key="n" v-model="stars" :value="n" color="orange" @change="filterate()">
              <template v-slot:label>
                <p class="body-1 font-weight-medium mb-0">{{n}} Stars</p>
              </template>
            </v-checkbox>
            <v-btn text color="blue" @click="allHotels('stars')">All Stars <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </div>
        </v-expand-transition>
        <v-divider class="my-5"></v-divider>

        <div @click="showRatesFilter = !showRatesFilter" class="d-flex justify-space-between">
          <h4 class="primary--text mt-1">Rates</h4>
          <v-icon right v-text="showRatesFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>
         <v-expand-transition>
          <div v-show="showRatesFilter">
            <v-checkbox v-for="(rate, i) in rates" :key="i" v-model="selectedRate" :value="rate.tripAdviserRating" color="orange" @change="filterate()">
              <template v-slot:label>
                <p class="body-1 font-weight-medium mb-0">{{rate.tripAdviserRating}} <small>/{{rate.numberOfHotels}} Hotels</small></p>
              </template>
            </v-checkbox>
            <v-btn text color="blue" @click="allHotels('rates')">All Rates <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </div>
         </v-expand-transition>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      hotels: [],
      rates: [],
      selectedRate: [],
      prices: [],
      min: 0,
      max: 0,
      stars: [1, 2, 3, 4, 5],
      // height: 0,
      hotelsCount: 0,
      showPriceFilter: true,
      showStarsFilter: true,
      showRatesFilter: true
    }
  },
  methods: {
    filterate () {
      const filtered = []
      this.hotels.forEach(hotel => {
        if (
          this.prices[0] <= hotel.pricingInfo.totalPrice &&
          hotel.pricingInfo.totalPrice <= this.prices[1] &&
          (this.stars.includes(hotel.stars) || !this.stars.length) &&
          (this.selectedRate.includes(hotel.TripAdviserRating) || !this.selectedRate.length)
        ) {
          filtered.push(hotel)
        }
      })
      this.hotelsCount = filtered.length
      this.$emit('changed', filtered)
    },
    allHotels (type) {
      const filtered = []
      if (type === 'rates') {
        this.selectedRate = []
        this.hotels.forEach(hotel => {
          if (this.prices[0] <= hotel.pricingInfo.totalPrice <= this.prices[1] && hotel.TripAdviserRating === (this.selectedRate > 0 ? this.selectedRate : hotel.TripAdviserRating)) {
            filtered.push(hotel)
          }
        })
        this.hotelsCount = filtered.length
        this.$emit('changed', filtered)
      } else {
        this.stars = []
        this.hotels.forEach(hotel => {
          if (this.prices[0] <= hotel.pricingInfo.totalPrice <= this.prices[1] && hotel.stars === (this.stars > 0 ? this.stars : hotel.stars)) {
            filtered.push(hotel)
          }
        })
        this.hotelsCount = filtered.length
        this.$emit('changed', filtered)
      }
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.hotels.minPrice)
    this.max = Math.floor(this.$store.state.hotels.maxPrice)
    this.prices = [this.min, this.max]
    this.rates = this.$store.state.hotels.tripAdviserRatingFilter
    this.hotels = [...this.$store.state.hotels.Hotels]
    this.hotelsCount = this.hotels.length
    // window.addEventListener('scroll', this.watchScroll)
    // this.height = window.innerHeight
  },
  destroyed () {
    // window.removeEventListener('scroll', this.watchScroll)
  }
}
</script>

<style>
.v-slider--horizontal .v-slider__track-container {
height: 4px !important;
}
/* .vue-range-slider.slider-component .slider .slider-process {
background-color: #FF8F95;
}
.vue-range-slider.slider-component .slider .slider-dot {
box-shadow: 0px 3px 6px #00000029;
} */
.v-slider__thumb::after {
  content: "";
  width: 14px !important;
  height: 14px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 2px #cccccc4a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>
